.filter-bar-conciliacao{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 40px 0;
  .filter-item input,
  .filter-item select{
    background-color: #fff;

  }
  
}