.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
}

.header .left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.header .left button {
  background-color: var(--primary-color);
  border-radius: 20px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  >svg {
    transform: translateY(-15%);
    margin-right: 10px;
  }
}

.header .search-div {
  display: flex;
  align-items: center;
  width: 300px;
  padding: 0 10px;
  margin-left: 100px;
  border-radius: 20px;
  background-color: var(--white);

  >input {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding: 13px;
    border: none;
    background-color: transparent;
  }

  >input:focus {
    outline: 0;
  }
}

.header .right {
  display: flex;
  align-items: center;
  width: auto;
  padding: 8px 20px;
  border-radius: 20px;
  background-color: var(--white);

  >h5 {
    border: 1px solid #dedede;
    padding: 2px 8px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
  }
}



.header .right>span {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid #dedede;
  padding: 3px 5px;
  margin-right: 20px;
}

.header .right>div {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border: 1px solid #dedede;
  padding: 5px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  >div {
    background-color: #dedede;
    border-radius: 20px;
    padding: 8px;
  }
}


/* Responsividade */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header .left {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .header .left .search-div {
    width: 100%;
    margin-left: 0;
  }

  .header .right {
    width: 100%;
    justify-content: space-between;
  }

  .header .right > span,
  .header .right > div {
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .header .left > button {
    padding: 5px 10px;
  }

  .header .left .search-div > input {
    padding: 10px;
  }

  .header .right > p {
    font-size: 12px;
  }
}