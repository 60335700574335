/* Sidebar.css */
.sidebar {
  width: 250px;
  background-color: #fff;
  padding: 20px 0 0 0;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  z-index: 999;
  transition: transform 0.3s ease;
}

.menu-icon {
  display: none;
  position: fixed;
  top: 0;
  left: -10px;
  z-index: 1000;
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: 24px;
}

.sidebar.open {
  transform: translateX(0);
}

@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
  }

  .menu-icon {
    display: block;
  }
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 100px;
}

.menu-container {
  flex-grow: 1;
  /* overflow-y: auto; */
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bottom-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

nav ul li,
.bottom-links ul li {
  margin-bottom: 10px;
}

nav ul li a,
.bottom-links ul li a,
.menu-item {
  text-decoration: none;
  font-weight: 500;
  color: var(--highlight-color);
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

nav ul li a.active,
.bottom-links ul li a.active {
  background-color: var(--background);
  border-left: 2px solid var(--highlight-color);
}

nav ul li a svg,
.menu-item svg {
  margin-right: 10px;
  color: var(--primary-color);
}

.bottom-links ul li a svg {
  margin-right: 10px;
}

.submenu svg {
  color: var(--highlight-color);
  width: 20px;
  transform: translateY(25%);
}

.menu-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  color: var(--highlight-color);
}

nav ul li a:hover,
.menu-item:hover {
  background-color: #f0f0f0;
}

.submenu {
  list-style: none;
  background-color: #f3f3f363;
}

.submenu li a {
  font-weight: 100;
  font-size: 14px;
  padding: 5px;
  padding-left: 20px;
  display: block;
  color: var(--highlight-color);
}

.submenu li a.active {
  background-color: var(--background);
}

.submenu-open > .menu-item {
  background-color: #f0f0f0;
}

.divider {
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

.logout-button {
  background: none;
  color: var(--highlight-color);
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 100;
}


/* Butao de cancelar assinatura */
.button-link {
  background: none;
  color: var(--highlight-color);
  width: 100%;
  border-radius: 0;
  text-align: left;
  padding-top: 0;
  >.icon{
    margin-right: 6px;
    padding: 0;
  }
  &:hover {
    background-color: #f0f0f0;
  }
}
