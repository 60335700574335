/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

/* Definição das variáveis de cor */
:root {
  --primary-color: #01dd01;
  --highlight-color: #0043eb;
  --black: #000000;
  --white: #ffffff;
  --background: #f3f3f3;
  --text-color: #6B6B6B;
  --error-color: #EB0000;
  --success-color: #0B9055;
  --warning-color: #D5A172;
  --secondary-error: #FFF3F3;
  --secondary-sucess: #DCFAE6;
  --secondary-warning: #FEFAEF;
  --thead-background: #f9f4fb;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background);
  color: var(--text-color);
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: var(--background);
  margin-left: 250px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.container {
  display: flex;
}

.content {
  background-color: var(--white);
  border-radius: 12px;
  padding: 20px;
  min-height: 300px;
}

h1 {
  font-size: 24px;
  color: #0043eb;
  font-weight: 500;
}

button {
  background-color: var(--highlight-color);
  color: var(--white);
  border: none;
  padding: 10px 25px;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  opacity: .8;
}

button:hover {
  opacity: 1;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Estilo padrão para tabelas */
.table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  text-align: left;
  background-color: var(--white);
}

.table thead tr {
  background-color: var(--thead-background);
  color: var(--highlight-color);
}

.table th,
.table td {
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  font-weight: 300;
}

.table tbody tr {
  border-bottom: 1px solid #ddd;
}


.table .status {
  border-radius: 10px;
  padding: 5px 10px;
  background-color: var(--secondary-warning);
  color: var(--warning-color);
  font-weight: bold;
  display: inline-block;
}

.table .status.vencido {
  background-color: var(--secondary-error);
  color: var(--error-color);
}

.table .status.recebido {
  background-color: var(--secondary-sucess);
  color: var(--success-color);
}

.table .status.a-receber {
  background-color: var(--secondary-warning);
  color: var(--warning-color);
}

.table .status.pago {
  background-color: var(--secondary-sucess);
  color: var(--success-color);
}

.table .status.a-pagar {
  background-color: var(--secondary-warning);
  color: var(--warning-color);
}

.table .nf-badge {
  background-color: #E6EDFF;
  color: var(--highlight-color);
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: 500;
  display: inline-block;
}

.table .actions {
  position: relative;
}

.table .actions button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
  font-size: 20px;
  letter-spacing: 1px;
}

.table .actions .tooltip {
  display: none;
  position: absolute;
  right: 0;
  top: 20px;
  background: var(--white);
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}


.table .actions .tooltip ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.table .actions .tooltip ul li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.table .actions .tooltip ul li:last-of-type {
  border-bottom: none;
}

.table .actions .tooltip ul li:hover {
  background: var(--thead-background);
}

.table .actions .tooltip ul li.remove {
  color: var(--error-color);
}

.table .actions button:focus+.tooltip,
.table .actions .tooltip:hover {
  display: block;
}

.content-table {
  padding: 20px 0;
  padding-bottom: 60px;

  >h1 {
    margin-left: 20px;
  }
}

/* Responsividade */
@media (max-width: 768px) {

  .table,
  .table thead,
  .table tbody,
  .table th,
  .table td,
  .table tr {
    display: block;
  }

  .table thead tr {
    display: none;
  }

  .table tr {
    margin-bottom: 15px;
  }

  .table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}

/* Estilos para o formulário */
form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.form-group {
  flex: 1 1 100%;
  margin-bottom: 20px;
}

.form-group-modal {
  display: flex;
  gap: 20px;
}

.form-group.half {
  flex: 1 1 48%;
}

label {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
  color: var(--text-color);
}


input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="number"],
input[type="password"],
select {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  color: var(--text-color);
  background-color: #F0F3F5;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="date"]:focus,
input[type="number"]:focus,
select:focus {
  outline: none;
  border-color: var(--highlight-color);
}

.form-group>span,
.form-group-modal>span {
  font-size: 11px;
  margin-left: 10px;
}

/* Incone de olho da mostrar senha */
.password-wrapper .fa {
  margin-left: -30px;
  transform: translateY(25%);
  cursor: pointer;
}

/* Paginação */
.paginacao-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-top: 1px solid #dcdcdc;
  margin-top: 20px;
  font-size: 14px;
}

.itens-por-pagina {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 10px;
}

.paginacao-texto select{
  width: 55px;
  margin-left: 6px;
  border-radius: 8px;
  padding: 4px;
}

.botao-paginacao {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.botao-paginacao:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.botao-paginacao:hover:not(:disabled) {
  background-color: #f0f0f0;
}

.paginacao-detalhes {
  display: flex;
  align-items: center;
  gap: 5px;
}

.seta-icon {
  width: 20px;
  height: 20px;
}