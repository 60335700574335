.container-certificado {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  height: 85vh;
}

.container-certificado h2 {
  color: var(--highlight-color);
  margin-bottom: 20px;
  letter-spacing: .5px;
}

.content-certificado {
  position: relative;
  background: linear-gradient(to right, #01DD01, #5fb15f);
  height: 300px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.texto-certificado {
  text-align: center;
  color: #fff;
  letter-spacing: .8px;
  padding: 40px 0;
}

.upload-certificado {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  bottom: -80%;
  width: 80%;
  height: 100%;
  max-width: 1000px;
  min-height: 350px;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.upload-certificado {
  padding: 20px;
}

.certificado-incluido h3 {
  color: #333;
}

.certificado-vencimento {
  font-size: 16px;
  color: var(--primary-color);
}

.vencimento-texto {
  color: #999;
}

.vencimento-data {
  font-weight: bold;
}

.vencimento-dias {
  color: #999;
}

.certificado-data {
  margin-top: 5px;
  color: #666;
}

.botoes-certificado {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.botao-alterar {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.botao-remover {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.certificado-upload h3 {
  color: #555;
}

.upload-field {
  margin-bottom: 20px;
}

.upload-label {
  background-color: #eee;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  display: flex;
  gap: 6px;
}

.input-arquivo {
  display: none;
}

.formatos-validos {
  margin-top: 5px;
  font-size: 12px;
  color: #999;
}

.senha-field {
  margin-bottom: 20px;
}

.input-senha-container {
  display: flex;
  align-items: center;
  position: relative;
}

.input-senha {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
}

.toggle-password {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.botao-enviar {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  width: 200px;
  cursor: pointer;
}

.botao-cancelar {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
