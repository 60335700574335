.redirect-button {
  background-color: transparent;
  color: var(--primary-color);
}

.icon {
  width: 25px;
  height: 25px;
  margin-top: 5px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

/* Tooltip (Texto do hover) */
.tooltip-text {
  visibility: hidden;
  box-shadow: 1px 1px 3px 0;
  background-color: #f5f5f5;
  width: 100px;
  color: var(--text-color);
  text-align: center;
  font-size: 13px;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  bottom: -50%; /* Posiciona o tooltip acima do botão */
  right: -110px;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 9999;
}

/* Exibir o tooltip ao passar o mouse */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}