.div-sifrao {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 2px solid #F0F3F5;
  border-radius: 0;
  position: relative;
}

.div-sifrao input {
  background-color: #fff;
  width: 80%;
  margin-top:5px;
  position: absolute;
  top: 0;
  border-radius: 0;
}

.div-sifrao> span {
  background-color: #F0F3F5;
  height: 100%;
  padding: 10px;
}

.svg-like {
  cursor: pointer;
}
.svg-like.received svg {
  color: var(--primary-color);
}
.svg-like.overdue {
  color: var(--error-color);
}

/* Modal de excluão de contaa receber  */
.delete-modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.delete-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  background-color: var( --background);
  padding: 10px 20px;
  border-radius: 20px;
  >div{
    display: flex;
    flex-direction: column;
  }
}

.delete-warnings {
  margin-top: 10px;
}

.delete-warnings p {
  margin: 5px 0;
}

.delete-warnings p.warnig-conta {
  font-size: 14px;
  background-color: var(--secondary-warning);
  color: var(--warning-color);
  padding: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  >svg{
    width: 50px;
  }
}
.delete-warnings p.error-conta {
  font-size: 14px;
  color: var(--error-color);
  background-color: var(--secondary-error);
  padding: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  >svg{
    width: 50px;
  }
}

/* Modal de confirma recebimento */

.confirm-modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.confirm-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  background-color: var( --background);
  padding: 10px 20px;
  border-radius: 20px;
  >div{
    display: flex;
    flex-direction: column;
  }
}

.status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: var(--warning-color);
  color: var(--white);
  margin-top: 10px;
}

.expandable-section {
  padding: 10px;
  border-radius: 10px;
}


.totais-section {
  position: sticky;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  padding: 10px 0;
  border-radius: 12px;
  background-color: var(--white);
  z-index: 100;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.5);
}

.total-item {
  flex: 1;
  text-align: center;
}

.total-item span:first-child {
  font-weight: bold;
  display: block;
}
