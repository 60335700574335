.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay escurecido */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Certifique-se de que o z-index esteja alto o suficiente para sobrepor outros elementos */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loading-circle {
  width: 120px;
  height: 120px;
  border: 8px solid transparent;
  border-top: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: relative;
}

.loading-circle:before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  border-radius: 50%;
  border: 8px solid;
  border-color: var(--primary-color) transparent transparent transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
