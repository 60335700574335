.filter-bar-container {
  display: flex;
  flex-direction: column;
}

.filter-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.add-button-receber {
  display: flex;
  align-items: center;
  background-color: var(--highlight-color);
  color: var(--white);
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.add-button-receber svg {
  margin-right: 5px;
}

.date-filter,
.period-button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--white);
  color: var(--text-color);
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 15px;
  margin-right: 10px;
  width: 250px;
}


.filter-button {
  display: flex;
  align-items: center;
  background-color: var(--white);
  color: var(--text-color);
  padding: 10px 15px;
}

.date-filter svg,
.period-button svg,
.filter-button svg {
  margin-right: 5px;
  color: var(--primary-color);
  cursor: pointer;
}

.icon-left, .icon-right {
  color: gray !important;
  background-color: white;
  border: 1px solid gray;
  border-radius: 50%;
  padding: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filters {
  position: relative;
}

.date-picker-modal {
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  top: 40px;
  right: 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  >span {
    color: var(--highlight-color);
    font-weight: 600;
    margin-top: 15px;
  }
}

.date-picker-modal button {
  margin-top: 10px;
}

.filter-button {
  background-color: transparent;
  border: none;
  color: var(--highlight-color);
  font-size: 16px;
  font-weight: 500;
  padding: 0;
}

.export-button {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  /* border: 1px solid var(--highlight-color); */
  color: var(--highlight-color);
  font-size: 16px;
  font-weight: 500;
  padding: 4px 20px;
}

.filter-button svg, .export-button svg {
  color: var(--highlight-color);
  width: 20px;
}

.filters {
  display: flex;
  align-items: center;
}

.advanced-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: var(--background);
  border-top: 1px solid #e0e0e0;
  margin-top: 10px;
}

.advanced-filters .form-group {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.container-filtrar-pesquisar {
  display: flex;
  
  padding: 10px;
}

.container-pesquisar {
  position: relative;
  display: flex;
  flex-direction: column;
  >input {
    position: absolute;
    top: 30px;
    right: -250px;
    background-color: #fff;
    margin-bottom: 5px;
    width: 300px;
    box-shadow: 1px 1px 3px 0;
  }  
}

.input-checkbox {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  gap: 10px;
  transform: translateY(50%);
}

.advanced-filters>.form-group h5 {
  margin-bottom: 5px;
  color: var(--text-color);
  font-weight: 500;
}

.advanced-filters .form-group button {
  padding: 5px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  color: var(--text-color);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 260px;
  >div{
    display: flex;
    gap: 5px;
  }
}

.advanced-filters .form-group button svg {
  width: 20px;
}

.modal-filter {
  position: absolute;
  top: 310px;
  background-color: var(--white);
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 400px;
  max-height: 500px;
  overflow-y: auto;
}

.modal-filter>span {
  margin-bottom: 40px !important;
  border-bottom: 1px solid var(--text-color);
  font-weight: 500;
  padding: 5px 10px;
}

.modal-filter ul {
  list-style-type: none;
  padding: 10px;
  margin: 0;
}

.modal-filter ul li {
  margin-bottom: 10px;
  font-size: 14px;
}

.modal-filter ul li input {
  margin-right: 10px;
}

.modal-filter ul li label {
  display: flex;
  align-items: center;
}

.clear-filters-button {
  background-color: #f44336;
  padding: 2px 10px !important;
  font-size: 12px;
  letter-spacing: .5px;
}

/* Bola de contagem */
.filter-count {
  background-color: var(--highlight-color);
  color: white;
  border-radius: 50%;
  padding: 1px 6px;
  font-size: 12px;
}


@media (max-width: 768px) {
  .filter-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .add-button-receber,
  .date-filter,
  .period-button,
  .filter-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .advanced-filters {
    flex-direction: column;
  }

  .advanced-filters .form-group {
    width: 100%;
    margin-bottom: 10px;
  }
}