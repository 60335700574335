.container-carteira {
  display: flex;
  flex-direction: column;
  padding: 40px 10px;

  .h3-icon {
    display: flex;
    align-items: center;

    >h3 {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 500;

      .walet-icon {
        font-size: 30px;
        color: var(--primary-color);
      }
    }
  }
}

/* Cards conta */
.contas {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.card-conta {
  width: 350px;
  height: 300px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
}

.banco-logo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 16px;
}

.banco-info {
  flex: 1;
}

.banco-info h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.banco-dados {
  display: flex;
  gap: 8px;
}

.banco-dados span {
  background-color: #F8F5FA;
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 12px;
}

.saldo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
}

.menu-icon-conta {
  margin-top: -20px;
  font-size: 30px;
  cursor: pointer;
  color: var(--text-color);
  padding: 0;
  background: none;
}

.card-conta .card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
}

.card-conta .saldo-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-left: 3px solid var(--primary-color);
  padding-left: 10px;

  .span-total {
    font-size: 20px;
    font-weight: 600;
  }
}

.card-conta .saldo-total span {
  font-size: 14px;
}

.card-conta .saldo-total span:first-child {
  color: #888;
}

/* card de adicionar conta */
.add-conta {
  position: relative;
  background: none;
  box-shadow: none;
  border: 1px dashed var(--text-color);
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
}

.add-button-conta {
  background: var(--primary-color);
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  .plus-icon {
    transform: translateY(10%);
  }
}

.banco-logo-placeholder {
  width: 40px;
  height: 40px;
  background: var(--primary-color);
  border-radius: 5px;
  margin-right: 10px;
}

/* Tooltip */

.saldo-tooltip {
  position: relative;
}

.tooltip-carteira {
  position: absolute;
  top: 20px;
  right: 0;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
}

.tooltip-carteira ul {
  width: 120px;
}

.tooltip-carteira li {
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #DEDEDE;
  cursor: pointer;
}

.tooltip-carteira li:hover {
  background: #f5f5f5;
}


/* Detalhes da conta */
.detalhes-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 20px;
}

.banco-logo {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.banco-info-detalhes {
  flex: 1;
}

.banco-info-detalhes h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.banco-dados-detalhes {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: var(--text-color);
  width: 300px;
  border-bottom: 1px solid #DEDEDE;

  >div {
    display: flex;
    gap: 10px;
  }

  >p {
    font-size: 18px;
    font-weight: 500;
  }
}

.integrada-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #DEDEDE;
  color: var(--text-color);
  font-size: 14px;
  letter-spacing: 1px;

  >.icon {
    font-size: 20px;
    color: var(--primary-color);
  }
}

.integrar-button {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;

  >.icon {
    font-size: 20px;
    color: #fff;
  }
}

.saldo-atual {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 8px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.saldo-atual span {
  font-size: 12px;
}

.saldo-atual h2 {
  font-size: 20px;
  font-weight: 500;
}

.resolv-conciliacao {
  width: 250px;
  float: right;
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 20px 10px 0 0;
  background: none;
  border: 1px solid var(--highlight-color);
  color: var(--highlight-color);

  >.icon {
    font-size: 24px;
  }
}

/* tabela de transacoes */
.td-transacao-extrato {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.td-data-extrato>span {
  background-color: var(--secondary-sucess);
  color: var(--success-color);
  padding: 2px 4px;
  border-radius: 6px;
}

.td-valor-extrato {
  color: var(--text-color);
}

/* Estilos para status e valores baseados no estado de conciliação */
.status-conciliado>span,
.status-naoConciliado>span,
.status-sugestao>span,
.valor-naoConciliado>span {
  padding: 6px 40px;
}

.status-conciliado>span {
  background-color: var(--secondary-sucess);
  color: var(--primary-color);
}

.status-naoConciliado>span {
  background-color: var(--secondary-error);
  color: var(--error-color);
}

.status-sugestao>span {
  background-color: var(--background);
  color: var(--text-color);
}

.valor-naoConciliado {
  color: var(--error-color);
}

@media (max-width: 768px) {
  .table-extrato .table thead {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  /* Ocultar "Últimas transações" no modo responsivo */
  .table-extrato .table thead th:first-child {
    display: none;
  }

  .table-extrato .table tr {
    border-bottom: 1px solid #DEDEDE;
    overflow: hidden;
  }

  .table-extrato .table td {
    border: none;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Ajustes para a td de transações ocupar toda a linha */
  .table-extrato .td-transacao-extrato {
    max-width: 100%;
    padding-left: 12px;
    text-align: left;
    display: block;
    position: relative;
    left: 0;
    width: auto;
  }

  /* Ocultar o label 'Últimas transações' no modo responsivo */
  .table-extrato .table td.td-transacao-extrato:before {
    content: none;
  }
}



/* Cadastrar conta bancaria */
.cadastro-conta-container {
  padding: 20px;
  background-color: var(--white);
  border-radius: 20px;
  margin: 10px 0;
}

.steps-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  >h2 {
    font-size: 18px;
  }
}

.step-indicator2 {
  display: flex;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
  padding-bottom: 40px;
  position: relative;
}

.cadastro-conta-container .step {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  position: relative;
  z-index: 1;
}

.cadastro-conta-container .step.active {
  color: var(--highlight-color);
}

.cadastro-conta-container .step.active span {
  border: 1px solid var(--highlight-color);
  color: var(--highlight-color);
  background-color: #fff;
}

.cadastro-conta-container .step span {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid var(--text-color);
  color: var(--text-color);
  background-color: var(--background);
  text-align: center;
  line-height: 28px;
}

.step-indicator2::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--text-color);
  z-index: 0;
}

.cadastro-conta-container .step::after {
  content: '';
  position: absolute;
  top: 15px;
  left: 50%;
  width: 50%;
  height: 2px;
  background: var(--text-color);
  z-index: -1;
}

.cadastro-conta-container .step.active::after {
  background: var(--highlight-color);
  left: 0;
  width: 100%;
}

.cadastro-conta-container .step-content {
  padding: 20px 60px;
}

.cadastro-conta-container .step-content .step1,
.cadastro-conta-container .step-content .step4,
.cadastro-conta-container .step-content .step3,
.cadastro-conta-container .step-content .step2 {
  display: flex;
  flex-direction: column;
  gap: 40px;

  >h3 {
    font-weight: 500;
  }
}

.account-types {
  display: flex;
  justify-content: center;
  gap: 20px;

  >div {
    width: 200px;
    border-radius: 20px;
  }
}

.account-type {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #6B6B6B;
  border-radius: 10px;
  cursor: pointer;
}

.account-type:hover {
  border: 2px solid var(--highlight-color);
}

.account-type.selected {
  border: 2px solid var(--highlight-color);
}

.account-type .icon {
  font-size: 22px;
  margin-bottom: 10px;
  color: var(--highlight-color);
}

.cadastro-conta-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cadastro-conta-container .form-group {
  width: 60%;
  margin: 0;
}

.cadastro-conta-container .form-group input,
.cadastro-conta-container .form-group select {
  width: 100%;
  border-radius: 0;
  padding: 15px;
}

.step-content .step3 {
  align-items: center;
}

.step-content .step4>h2 {
  text-align: center;
  letter-spacing: 1px;
}

.form-group-agencia-conta {
  display: flex;
  gap: 10px;
  width: 60%;
}

.form-group-cifrao input {
  width: 90% !important;
  padding: 10px !important;
}

.group-checkbox {
  display: flex !important;

  >label {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;

    >input {
      width: auto !important;
    }
  }
}

.optional-data {
  border-top: 1px solid #D9D9D9;
  padding-top: 30px;
}

.cadastro-conta-container .buttons {
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 20px;
  margin: 40px auto;
  border-top: 1px solid #D9D9D9;
  width: 80%;
}

.back-button {
  background-color: #ccc;
  color: #fff;
}

.next-button {
  background-color: var(--primary-color);
  color: #fff;
}

.next-button:disabled {
  background-color: #aaa;
}

.error {
  color: red;
  font-size: 12px;
}

/* Flega de conta desativada */
.flag-inativa {
  color: red;
  font-weight: bold;
  font-size: 12px;
  margin-top: 5px;
}

/* Checkbox conta principal */
.conta-principal{
  display: flex;
  align-items: center;
  gap: 5px;
  >span{
    font-size: 14px;
  }
}